import DeskNav from './DeskNav'
import PhoneNav from './PhoneNav'

const navLinks = [
	{
		name: 'Business',
		children: [
			{ name: 'Home', to: '/' },
			{ name: 'Health Days', to: '/health-days' },
			{ name: 'Lunch & Learn', to: '/lunch-learn' },
			{ name: 'Diagnostics', to: '/diagnostics' },
			{ name: 'Health Cash Plan', to: '/health-cash' }
		]
	},
	{
		name: 'Clinic',
		to: '/clinic'
	},
	{
		name: ' helfy®Partners',
		to: '/helfy-partners'
	},
	{
		name: 'About',
		to: '/about'
	}
]

const NavBar = () => {
	return (
		<>
			<DeskNav
				navLinks={navLinks}
				className="hidden lg:block"
			/>

			<PhoneNav
				navLinks={navLinks}
				className="block lg:hidden"
			/>
		</>
	)
}

export default NavBar
