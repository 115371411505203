// import { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from "./navBar/NavBar"
import Footer from './Footer'

const Layout = () => {
	// useEffect(() => {
	// 	// NOTE: Force scroll to go to the the top when reloading
	// 	window.scrollTo(0, 0)
	//   }, [])
	  
	return (
		<div className="h-full">
			<Navbar />
			<Outlet />
			<Footer />
		</div>
	)
}

export default Layout